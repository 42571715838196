import { takeEvery, takeLatest, put, call, select } from 'redux-saga/effects';
import uuid from 'uuid';

import { setItems } from 'components/TableDragDropSelfStanding/actions';
import { selectItems } from 'components/TableDragDropSelfStanding/selectors';
import config from 'config/config';
import { selectRouteParams } from 'containers/GlobalWrapper/selectors';
import Analytics from 'utils/Analytics';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { uploadMedia, uploadBimChunks } from 'utils/chunkUploader';
import { locationType } from 'utils/constants';
import { checkParams } from 'utils/errors/check-params';
import { getBinaryDataFileWindow } from 'utils/file';
import localStorageUser from 'utils/localStorageUser';
import request from 'utils/request';

import { customerIoTrackerWithParams } from '../../../utils/CustomerIo';
import {
  setLoadingFlag,
  setDataItem,
  setZoningData,
  moveFolders,
  movePlans,
  setSmartZoningToValidateCount,
} from './actions';
import {
  ADD_FOLDER,
  DELETE_ITEMS,
  SAVE_ORDERED_ITEM,
  MOVE_ITEMS_TO,
  MOVE_PLANS_TO,
  MOVE_FOLDERS_TO,
  RENAME_ITEM,
  UPLOAD_PLAN,
  SAVE_ITEM,
  FETCH_DATA_ITEM,
  UPLOAD_FOLDER,
  DELETE_IMAGE,
  GET_FOLDERS_CONTENT,
  FETCH_ZONE_DATA,
  SET_PLANS_TO_UPLOAD,
  UPLOAD_PLAN_SUCCESS,
  UPLOAD_PLAN_FAILED,
  GET_SMART_ZONING_TO_VALIDATE_COUNT,
  CREATE_SMART_ZONING_FOLDER,
  UPDATE_PLAN,
  GET_PLAN,
  GET_ALL_PLANS,
  GET_ALL_FOLDERS,
} from './constants';
import { filterSuccessfulUpload, filterFailedUpload } from './utils/plan';

export function* postFolder({ values, namespace, callback }) {
  yield put(setLoadingFlag(true));

  const tableList = yield select(selectItems(namespace));
  const prevList = JSON.parse(JSON.stringify(tableList));

  const route = yield select(selectRouteParams());

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/folders`;

  const itemId = uuid.v4();
  const options = {
    method: 'POST',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: values.name,
      id: itemId,
      parent_folder_id: route?.itemId,
    }),
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.error) {
    Analytics.track('plans_folder_creation_succeeded', {
      with_overview: !!values.resources?.uuidBlobFile,
    });
    yield put(setItems([...prevList, { ...data, type: locationType.folder }], namespace));
    yield call(callback);
  }
  yield put(setLoadingFlag(false));
}

export function* setPlansToUpload({ resources, namespace }) {
  yield put(setLoadingFlag(true));
  const tableList = yield select(selectItems(namespace));
  const prevList = JSON.parse(JSON.stringify(tableList));
  yield put(setItems([...prevList, ...resources], namespace));
}

export function* successPlansUpload({ plans, namespace }) {
  yield put(setLoadingFlag(true));
  const tableList = yield select(selectItems(namespace));
  const filteredUploads = filterSuccessfulUpload(tableList, plans);
  yield put(setItems(filteredUploads, namespace));
}

export function* failedPlansUpload({ plans, namespace }) {
  yield put(setLoadingFlag(true));
  const tableList = yield select(selectItems(namespace));
  const filteredUploads = filterFailedUpload(tableList, plans);
  yield put(setItems(filteredUploads, namespace));
}

function* processPostPlansResponse(organizationId, projectId, isBim, item, callbackSuccess, data) {
  const planType = isBim ? locationType.bim : locationType.plan;
  let type = 'image';
  const isPdf = item?.resources?.type?.includes('/pdf');
  const isDwg = item?.resources?.type?.includes('dwg') || item?.resources?.type?.includes('acad');
  const isIfc = item?.resources?.type?.includes('ifc');
  if (isPdf) {
    type = 'pdf';
  } else if (isDwg && !isBim) {
    type = 'dwg';
  } else if ((isDwg && isBim) || isIfc) {
    type = 'ifc';
  }
  Analytics.track('plans_plan_creation_succeeded', {
    file_format: type,
  });
  yield customerIoTrackerWithParams('plans_plan_creation_succeeded', organizationId, projectId);
  yield call(callbackSuccess, {
    ...item,
    ...data,
    loading: item.loading,
    version: data.revision,
    type: planType,
  });
}

export function* postPlans({ item, callbackSuccess, callbackError }) {
  yield put(setLoadingFlag(true));

  const route = yield select(selectRouteParams());
  const isBim = item.name.indexOf('.ifc') > -1;
  const requestUploadMediaUrl = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.2/projects/${route.idData}/`;

  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.3/projects/${route.idData}/plans`;

  let payload;
  let data;

  if (!isBim) {
    const dataUpload = yield call(uploadMedia, item.resources, {
      baseUrl: requestUploadMediaUrl,
      parallel: false,
      requestHeaders: {
        'Content-Type': 'application/json',
      },
    });
    if (dataUpload && (dataUpload.message || dataUpload.status === 500)) {
      yield call(callbackError, item);
    } else {
      payload = {
        plan_id: item.id,
        folder_id: route.itemId || '',
        plan_type: isBim ? locationType.bim : locationType.plan,
        name: item.name,
        media_resource: {
          root_media_resource_id: dataUpload.id,
          media_resource_id: dataUpload.id,
          media_resource_label: dataUpload.file_name,
        },
      };

      const options = {
        method: 'POST',
        headers: {
          'Cache-Control': 'No-Store',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      };

      data = yield call(request, requestURL, options);
    }
  } else {
    data = yield call(uploadBimChunks, item);
  }

  if (data && (data.message || data.status === 500)) {
    yield call(callbackError, item);
  } else {
    yield* processPostPlansResponse(
      route.organizationId,
      route.idData,
      isBim,
      item,
      callbackSuccess,
      data,
    );
  }
  yield put(setLoadingFlag(false));
}

export function* updatePlans({ item, callbackSuccess, callbackError }) {
  yield put(setLoadingFlag(true));

  const route = yield select(selectRouteParams());
  const requestUploadMediaUrl = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.2/projects/${route.idData}/`;

  let payload;
  let data;

  const dataUpload = yield call(uploadMedia, item.resources, {
    baseUrl: requestUploadMediaUrl,
    parallel: false,
    requestHeaders: {
      'Content-Type': 'application/json',
    },
  });

  if (dataUpload && (dataUpload.message || dataUpload.status === 500)) {
    yield call(callbackError, item);
  } else {
    const requestURL = `${checkOrSetSlash(
      config.apiHostGateway,
      'apiHostGateway',
    )}api/v1.3/projects/${route.idData}/plans/${route.planId}`;

    payload = {
      ...item,
      folder_id: route.itemId && route.itemId !== 'root' ? route.itemId : null,
      media_resource: {
        root_media_resource_id: dataUpload.id,
        media_resource_id: dataUpload.id,
        media_resource_label: dataUpload.file_name,
      },
      thumbnail: null,
    };

    const options = {
      method: 'PUT',
      headers: {
        'Cache-Control': 'No-Store',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };

    data = yield call(request, requestURL, options);
  }

  if (data && (data.message || data.status === 500)) {
    yield call(callbackError, item);
  } else {
    yield* processPostPlansResponse(
      route.organizationId,
      route.idData,
      false,
      item,
      callbackSuccess,
      data,
    );
  }
  yield put(setLoadingFlag(false));
}

export function* fetchFoldersContent({ params, id = '', callback }) {
  yield put(setLoadingFlag(true));
  checkParams({ params, keys: ['idData'] });

  const folderQuery = id ? `/${id}/children-folders?` : '?';

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    params.idData
  }/folders${folderQuery}offset=0&limit=500`;

  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
    },
  };

  const data = yield call(request, requestURL, options);

  if (data && !data.message) {
    const response = id
      ? data.folders
      : data.folders.filter(
          element => !element.parent_folder_id || element.parent_folder_id === null,
        );

    yield call(callback, { folders: response });
  }
  yield put(setLoadingFlag(false));
}

export function* moveFoldersTo({ parentId, itemIds, callback }) {
  const route = yield select(selectRouteParams());

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/folders/change-parent-folder`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      parent_folder_id: parentId !== 'root' ? parentId : null,
      moved_folder_ids: itemIds,
    }),
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    yield call(callback, data);
  }
}

export function* saveOrderedItem({ item }) {
  const route = yield select(selectRouteParams());
  yield put(setLoadingFlag(true));
  const baseUrl = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/v1.3/projects/${
    route.idData
  }`;
  const requestURL =
    item.type === locationType.plan
      ? `${baseUrl}/plans/${item.id}`
      : `${baseUrl}/folders/${item.id || route.itemId}`;

  let finalItem = item;
  if (item.type === locationType.plan) {
    const requestGETURL = `${checkOrSetSlash(
      config.apiHostGateway,
      'apiHostGateway',
    )}api/v1.3/projects/${route.idData}/plans/${item.id}`;

    const optionsGET = {
      method: 'GET',
      headers: {
        'Cache-Control': 'No-Store',
        'Content-Type': 'application/json',
      },
    };

    const dataGet = yield call(request, requestGETURL, optionsGET);

    if (dataGet) {
      finalItem = dataGet;
    }
  }

  const rootMediaResourceId =
    route.itemId === undefined ? finalItem?.media_resource?.id : route.itemId;

  let thumbnail = null;
  if (item.thumbnail_resource_id) {
    thumbnail = {
      media_resource_id: item.thumbnail_resource_id,
      media_resource_label: item.name,
      root_media_resource_id: rootMediaResourceId,
    };
  }

  const payload = {
    ...finalItem,
    media_resource: {
      media_resource_id: finalItem?.media_resource_id || finalItem?.media_resource?.id,
      media_resource_label: finalItem.name,
      root_media_resource_id: rootMediaResourceId,
    },
    thumbnail,
    plan_update_model: 'null',
    parent_folder_id: route.itemId,
    order: item.order,
  };

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'No-Store',
    },
    body: JSON.stringify(payload),
  };

  const data = yield call(request, requestURL, options);

  if (data && !data.message && item.type === locationType.folder) {
    Analytics.track('Plan_folder_reorder_succeed');
  } else if (data && !data.message) {
    Analytics.track('Plan_plan_reorder_succeed');
  }
  yield put(setLoadingFlag(false));
}

export function* movePlansTo({ parentId, itemIds, callback }) {
  const route = yield select(selectRouteParams());

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/plans/change-parent-folder`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      parent_folder_id: parentId !== 'root' ? parentId : null,
      moved_plan_ids: itemIds,
    }),
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    yield call(callback, data);
  }
}

export function* moveItemsTo({ parentId, items, callback }) {
  // comment for flash action yield put(setLoadingFlag(true));
  const plans = items.filter(
    item => item?.type === locationType.plan || (item?.plan_type && item?.plan_type === 'Plan'),
  );
  const bims = items.filter(
    item => item?.type === locationType.bim || (item?.plan_type && item?.plan_type === 'Bim'),
  );
  const folders = items.filter(
    item => item?.type === locationType.folder || (item?.plan_type && item?.plan_type !== 'Plan'),
  );

  if (plans.length) {
    yield put(movePlans(parentId, plans.map(item => item.id), callback));
  }
  if (bims.length) {
    yield put(movePlans(parentId, bims.map(item => item.id), callback));
  }
  if (folders.length) {
    yield put(moveFolders(parentId, folders.map(item => item.id), callback));
  }

  // yield put(setLoadingFlag(false));
}

export function* deleteItem({ item, callback }) {
  yield put(setLoadingFlag(true));

  const route = yield select(selectRouteParams());
  const isPlan = item.type === locationType.plan || item.type === locationType.bim;
  const query = isPlan ? `/plans/${item.id}` : `/folders/${item.id}`;

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }${query}`;

  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const data = yield call(request, requestURL, options);
  if (((data && !data.message) || !data) && callback) {
    Analytics.track('plans_plan_delete_succeed');
    yield call(callback, data);
  }
  yield put(setLoadingFlag(false));
}

export function* renameItem({ item }) {
  yield put(setLoadingFlag(true));

  const route = yield select(selectRouteParams());
  const plansLocalStorage = localStorageUser.getRecentlyViewedPlans();
  const filterPlansByProject = plansLocalStorage.filter(plan => plan.project_id === route.idData);

  const requestPlan = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.3/projects/${route.idData}/plans/${item.id}`;

  const requestFolder = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.3/projects/${route.idData}/folders/${item.id}`;

  const optionsGET = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
  };

  const requestItem = item.type === locationType.plan ? requestPlan : requestFolder;
  const dataGet = yield call(request, requestItem, optionsGET);
  const finalItem = dataGet || item;

  const rootMediaResourceId =
    route.itemId === undefined ? finalItem.media_resource?.id : route.itemId;

  let thumbnail = null;
  if (item.thumbnail_resource_id) {
    thumbnail = {
      media_resource_id: item.thumbnail_resource_id,
      media_resource_label: item.name,
      root_media_resource_id: rootMediaResourceId,
    };
  }

  const payload = {
    ...finalItem,
    media_resource: {
      media_resource_id: finalItem.media_resource_id || finalItem.media_resource?.id,
      media_resource_label: finalItem.name,
      root_media_resource_id: rootMediaResourceId,
    },
    thumbnail,
    plan_update_model: 'null',
    folder_id: route.itemId,
    name: item.name,
  };

  const optionPlan = {
    method: 'PUT',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'No-Store',
    },
    body: JSON.stringify({ ...item, parent_folder_id: route.itemId }),
  };

  const optionUpdate = item.type === locationType.plan ? optionPlan : options;
  const data = yield call(request, requestItem, optionUpdate);
  if (data && !data.message && data.id) {
    const findItem = filterPlansByProject.find(itemId => itemId.id === item.id);
    if (findItem) {
      const { resources, ...smallItem } = data;
      localStorageUser.addRecentlyViewedPlans({
        ...smallItem,
        project_id: route.idData,
      });
    }
  }
  yield put(setLoadingFlag(false));
}

export function* saveItem({ item, callback }) {
  yield put(setLoadingFlag(true));
  const route = yield select(selectRouteParams());
  const baseUrl = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }`;
  const requestURL =
    item.type === locationType.plan
      ? `${baseUrl}/plans/${item.id}`
      : `${baseUrl}/folders/${item.id || route.itemId}`;

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'No-Store',
    },
    body: JSON.stringify(item),
  };

  const data = yield call(request, requestURL, options);
  yield put(setDataItem(data));
  yield call(callback, data);
  yield put(setLoadingFlag(false));
}

export function* fetchDataItem({ params }) {
  yield put(setLoadingFlag(true));
  yield put(setDataItem({}));
  checkParams({ params, keys: ['idData', 'itemId'] });

  const baseUrl = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    params.idData
  }`;
  const requestURL = `${baseUrl}/folders/${params.itemId}`;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const redirectionUrl = params.workspaceId
    ? `organization/${params.organizationId}/workspace/${params.workspaceId}/projects/${
        params.idData
      }/${params.pageName}`
    : `organization/${params.organizationId}/projects/${params.idData}/${params.pageName}`;

  const data = yield call(request, requestURL, options, {
    errorRedirect: true,
    redirectionUrl,
  });
  if (data && !data.message) {
    yield put(setDataItem(data));
  }
  yield put(setLoadingFlag(false));
}

export function* fetchzonesData({ zoneObj }) {
  yield put(setLoadingFlag(true));
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const fileData = yield call(request, `${zoneObj.url}/url?quality=Thumbnail`, options, {
    errorRedirect: false,
  });

  if (fileData.media_url) {
    const optionsFile = {
      method: 'GET',
      headers: {},
    };
    const data = yield call(request, fileData.media_url, optionsFile, {}, true);
    if (data && !data.message) {
      yield put(setZoningData(data));
    }
    yield put(setLoadingFlag(false));
  }
}

export function* uploadFolder({ folder, callback }) {
  const route = yield select(selectRouteParams());

  const formDataUpload = new FormData();
  formDataUpload.append('media_resource.media_resource_id', uuid.v4());
  formDataUpload.append('media_resource.media_resource_display_name', folder.resources.name);
  formDataUpload.append('media_resource.media_resource_mime_type', folder.resources.type);
  formDataUpload.append('media_resource.media_resource_size', folder.resources.size);

  formDataUpload.append('media_resource.media_resource_md5', folder.resources.hash);

  formDataUpload.append(
    'media_resource.media_resource_data',
    getBinaryDataFileWindow(folder.resources.uuidBlobFile),
  );

  const requestUploadURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.4/projects/${route.idData}/folders/${folder.id}/attach-media`;

  const optionsUpload = {
    method: 'POST',
    headers: {
      'Cache-Control': 'No-Store',
    },
    body: formDataUpload,
  };
  const dataUpload = yield call(request, requestUploadURL, optionsUpload);

  if (dataUpload && !dataUpload.message) {
    if (callback) {
      Analytics.track('plans_folder_overview_changed');
      yield call(callback);
    }
    yield put(setDataItem(dataUpload));
  }
}

export function* deleteImageFolder({ idImage }) {
  yield put(setLoadingFlag(true));
  const route = yield select(selectRouteParams());
  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/folders/${route.itemId}/detach-media`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      detach_media_resource_ids: [idImage],
    }),
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    yield put(setDataItem(data));
  }
  yield put(setLoadingFlag(false));
}

export function* deleteItems({ items, callback }) {
  yield (items || []).map(item => call(deleteItem, { item }));
  if (callback) {
    yield call(callback, items);
  }
}

export function* getSmartZoningToValidateCount() {
  const route = yield select(selectRouteParams());

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/folders/count-previews`;

  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
  };
  const data = yield call(request, requestURL, options, {
    errorRedirect: false,
  });
  if (data && !data.message) {
    yield put(setSmartZoningToValidateCount(data.count));
  }
}

export function* createSmartZoningFolder({ item, callback }) {
  const route = yield select(selectRouteParams());

  const requestUploadMediaUrl = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.2/projects/${route.idData}/`;

  const dataUpload = yield call(uploadMedia, item, {
    baseUrl: requestUploadMediaUrl,
    parallel: false,
    requestHeaders: {
      'Content-Type': 'application/json',
    },
  });
  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.3/projects/${route.idData}/folders/smartzoning`;

  const payload = {
    id: uuid.v4(),
    name: item.name,
    media_resource: {
      root_media_resource_id: dataUpload.id,
      media_resource_id: dataUpload.id,
      media_resource_label: dataUpload.file_name,
    },
  };

  const options = {
    method: 'POST',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };
  yield call(request, requestURL, options, {
    errorRedirect: false,
  });
  if (callback) {
    yield call(callback);
  }
}

export function* getPlan({ id, callbackSuccess, callbackError }) {
  const route = yield select(selectRouteParams());
  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/plans/${id}`;

  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
  };
  const data = yield call(request, requestURL, options, {
    errorRedirect: false,
  });
  if (data && !data.message) {
    if (callbackSuccess) {
      yield call(callbackSuccess, data);
    }
  } else if (callbackError) {
    yield call(callbackError, data);
  }
}

export function* dispatchGetAllPlans({ callback }) {
  const route = yield select(selectRouteParams());
  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/plans`;

  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
  };
  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    if (callback) {
      yield call(callback, data);
    }
  }
}

export function* dispatchGetAllFolders({ callback }) {
  const route = yield select(selectRouteParams());
  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/folders`;

  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
  };
  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    if (callback) {
      yield call(callback, data);
    }
  }
}
export default function* saga() {
  yield takeLatest(ADD_FOLDER, postFolder);
  yield takeEvery(UPLOAD_PLAN, postPlans);
  yield takeEvery(UPDATE_PLAN, updatePlans);
  yield takeLatest(GET_FOLDERS_CONTENT, fetchFoldersContent);
  yield takeEvery(DELETE_ITEMS, deleteItems);
  yield takeLatest(MOVE_FOLDERS_TO, moveFoldersTo);
  yield takeLatest(MOVE_PLANS_TO, movePlansTo);
  yield takeLatest(MOVE_ITEMS_TO, moveItemsTo);
  yield takeLatest(SAVE_ITEM, saveItem);
  yield takeLatest(RENAME_ITEM, renameItem);
  yield takeLatest(FETCH_DATA_ITEM, fetchDataItem);
  yield takeLatest(FETCH_ZONE_DATA, fetchzonesData);
  yield takeLatest(UPLOAD_FOLDER, uploadFolder); // TO-DO Chech if upload folder is still used
  yield takeLatest(DELETE_IMAGE, deleteImageFolder);
  yield takeLatest(SET_PLANS_TO_UPLOAD, setPlansToUpload);
  yield takeEvery(UPLOAD_PLAN_SUCCESS, successPlansUpload);
  yield takeEvery(UPLOAD_PLAN_FAILED, failedPlansUpload);
  yield takeEvery(SAVE_ORDERED_ITEM, saveOrderedItem);
  yield takeEvery(CREATE_SMART_ZONING_FOLDER, createSmartZoningFolder);
  yield takeEvery(GET_PLAN, getPlan);
  yield takeLatest(GET_SMART_ZONING_TO_VALIDATE_COUNT, getSmartZoningToValidateCount);
  yield takeLatest(GET_ALL_PLANS, dispatchGetAllPlans);
  yield takeLatest(GET_ALL_FOLDERS, dispatchGetAllFolders);
}
